import React, { useState } from "react";
import Parser from "html-react-parser";
import {
  Alert as AlertMU,
  Accordion as Acc,
  AccordionSummary as AccSum,
  Box,
  styled,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Dialog,
  IconButton,
  Grid,
  AccordionDetails,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import check from "../../assets/Check.png";
import { useDispatch, useSelector } from "react-redux";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { useNavigate } from "react-router-dom";
import { resetFile, setDocsStack, trueLoading } from "../../store/actions";
import { Alert } from "../../components/Alert";
import { DocumentButton } from "./DocumentButton";
import iconDocument from "../../assets/icons/Documento.png";
import { Close, ExpandMore } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import BackdropComponent from "../../components/BackDrop";
import { UTCtoLocal } from "../../utils";
import { DocumentButtonPrevious } from "./DocumentButtonPrevious";
import { MassiveService } from "../../services/MassiveService";
import ParticipantSortTableMasive from "./ParticipantSortTableMasive";
import { renderToString } from "react-dom/server";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  /* minHeight: "90vh", */
  // padding: "10px 10px",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 10px",
  },
}));

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "1px solid red",
  borderBottomColor: theme.palette.secondary.main40,
}));

export const FilePreviewMasive = (props) => {
  const navigate = useNavigate();
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const [docSelected, setdocSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [observacionesCurrent, setObservacionesCurrent] = useState("");

  const dispatch = useDispatch();
  const newFileInfo = useSelector((state) => state.NewFile.file);
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);
  const docsStack = useSelector((state) => state.NewFile.documentsStack);

  const dataPrerre = useSelector((state) => state.File?.item?.Prerequisites);

  const docsStackPrerreFile = useSelector((state) =>
    state.File.item?.Grupos?.find(
      (item) => item.Prerequisites.PrerequisitesDocument.length > 0
    )
  );

  console.log("fileState", docsStackPrerreFile);

  useEffect(() => {
    if (fileState?.item) {
      if (docsStack.length === 0) {
        const checkListDocs = fileState?.item?.Documents;
        dispatch(setDocsStack(checkListDocs));
      }
    }
  }, [fileState?.item]);

  const iniciarExpediente = async () => {
    setLoading(true);
    try {
      const massiveService = MassiveService.getInstance();
      const fileFlowData = await massiveService.InitializeBatchUpload({
        File_Id: folioIdSolicitud,
      });
      console.log("INICIARFLUJO", fileFlowData);
      if (fileFlowData.Body.Response) {
        setLoading(false);
        localStorage.removeItem("ctrlExpediente");
        localStorage.removeItem("ctrlReqPrevios");
        navigate("/inbox/files");
        Alert({
          icon: "success",
          title: "Tus solicitudes han sido iniciadas",
          okbtntext: "Aceptar",
          html: renderToString(
            <Box>
              <Typography
                sx={{
                  maxWidth: 400,
                  mx: "auto",
                  display: "inline",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "21px",
                  lineHeight: "37px",
                  fontFamily: '"Montserrat",sans-serif',
                  ["@media only screen and (max-width: 812px)"]: {
                    fontSize: "15px",
                    lineHeight: "20.72px",
                  },
                }}
              >
                Cantidad de solicitudes:{" "}
                <span style={{ color: "#8CE982" }}>
                  {fileFlowData.Body.Response.SubFileInfoList.length}
                </span>
                {/* {fileFlowData.Body.Response.SubFileInfoList?.map((item) =>
                  Parser(
                    `<ul><li style="color: #8CE982"><span style="color: #8CE982"}}> ${item?.Id} </span> </li></ul> `
                  )
                )} */}
              </Typography>
            </Box>
          ),
        });
        dispatch(trueLoading());
        dispatch(resetFile());
      } else {
        setLoading(false);
        navigate("/inbox/files");
        Alert({
          icon: "error",
          title: "Error",
          text: "Hubo un error. " + fileFlowData.Body.Message,
        });
        dispatch(trueLoading());
        dispatch(resetFile());
      }
    } catch (error) {
      Alert({
        icon: "error",
        title: "Error",
        text: "Hubo un error. " + error.message,
      });
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (fileState.items) {
      let observaciones = fileState?.item?.FileData?.Metadata?.find(
        (item) =>
          item.GroupName === "Alta Proveedor" && item.Name === "Observaciones"
      )?.Value;
      console.log(observaciones);
      setObservacionesCurrent(observaciones);
    }
  }, [fileState?.items]);

  console.log('groupUsers', fileState?.item)

  return (
    <RootStyle>
      <BackdropComponent loading={loading ||  fileState?.item === null} />
      <PreviewContainer>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="titulo">
              Información de la solicitud
            </Typography>
          </Box>
          <InfoLabel
            label="Fecha de creación:"
            value={UTCtoLocal(
              newFileInfo?.CreationDateFormatted ||
                fileState.item?.FileData?.CreationDate,
              true
            )}
          />
          <InfoLabel
            label="Vigencia de la solicitud:"
            value={UTCtoLocal(
              newFileInfo?.fileForm?.FileConfiguration?.Validity ||
                fileState.item?.Validity,
              false
            )}
          />
          <InfoLabel label="Observaciones:" value={observacionesCurrent} />
        </Box>
        <Box
          sx={{
            mb: 2,
            borderBottom: 1.5,
            borderColor: "secondary.main60",
            width: "90%",
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography variant="titulo">Documentos</Typography>
          </Box>
        </Box>

        <Box sx={(theme) => ({ boxShadow: theme.shadows[9], mt: 2 })}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{ mt: 2, display: { xs: "none", sm: "block" } }}
          >
            <Table
              sx={{
                minWidth: 650,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead
                sx={{
                  outline: "0px solid #FFFFFF",
                  // border: "0px solid #FFFFFF",
                  borderBottom: 1,
                  borderColor: "primary.main60",
                  borderRadius: 0,
                }}
              >
                <TableRow>
                  <TableCell sx={{ width: 300 }} align="left">
                    Nombre del documento
                  </TableCell>
                  <TableCell width={100} align="center">
                    Documento previo
                  </TableCell>
                  <TableCell width={100} align="center">
                    Complemento
                  </TableCell>
                  <TableCell width={100} align="center">
                    Edición o Captura
                  </TableCell>
                  <TableCell width={100} align="center">
                    Firma
                  </TableCell>
                  <TableCell width={50} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docsStackPrerreFile?.Prerequisites?.PrerequisitesDocument?.map(
                  (doc, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Box
                          sx={{
                            display: "flex",
                            ml: 1,
                            justifyContent: "left",
                            textAlign: "left",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            icon="system-uicons:document-stack"
                            style={{
                              color: "#C20E30",
                              fontSize: 24,
                              marginRight: 5,
                            }}
                          />
                          <Typography variant="body2">
                            {`Documento - ${doc.Name}`}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <b>-</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>-</b>
                      </TableCell>
                      <TableCell align="center">
                        <b>-</b>
                      </TableCell>
                    </TableRow>
                  )
                )}
                {docsStack.map((doc, index) => (
                  <TableRow
                    key={index + 1}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: "0px !important",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <DocumentButton
                        style={{ borderBottom: "0px !important" }}
                        key={doc.DocResponseId}
                        onSelect={() => setdocSelected(doc)}
                        customComponent={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              textAlign: "left",
                            }}
                          >
                            <img
                              style={{ width: 15, marginRight: 7 }}
                              alt="imagenLogo"
                              src={iconDocument}
                            />
                            <Typography variant="body2">
                              {`Documento - ${
                                doc?.DocumentConfiguration?.FileName ||
                                doc?.FileName
                              }`}
                            </Typography>
                          </Box>
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <b>-</b>
                    </TableCell>
                    <TableCell align="center">
                      {doc?.DocumentConfiguration?.OnlyView === true ||
                      doc?.OnlyView === true ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      ) : (
                        <b>-</b>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {doc?.DocumentConfiguration?.Check === true ||
                      doc?.Check === true ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      ) : (
                        <b>-</b>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {doc?.DocumentConfiguration?.Sign === true ||
                      doc?.Sign === true ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      ) : (
                        <b>-</b>
                      )}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={(theme) => ({
              boxShadow: theme.shadows[5],
              mt: 2,
              display: { xs: "block", sm: "none" },
            })}
          >
            {dataPrerre?.ActivePrerequisites === true
              ? dataPrerre?.UploadPrerrequisitesStatusDocument?.map((item) => (
                  <DocumentButtonPrevious titulo={item?.DocumentName} />
                ))
              : null}
            {newFileState.documentsStack.map((doc, i) => (
              <DocumentButton
                preview={true}
                key={doc.DocResponseId}
                doc={doc}
                listDocs={true}
              />
            ))}
          </Box>
        </Box>

        {docSelected && (
          <Dialog
            open={!!docSelected}
            onClose={() => setdocSelected(null)}
            fullWidth
            maxWidth="lg"
          >
            <Box sx={{ p: 2, position: "relative" }}>
              <IconButton
                size="small"
                sx={{ position: "absolute", right: 8, top: 2 }}
                onClick={() => setdocSelected(null)}
              >
                <Close fontSize="small" />
              </IconButton>
              <VisorPDF
                pdfUrl={docSelected?.fileUrl}
                pdfName={
                  docSelected.DocumentConfiguration
                    ? docSelected.DocumentConfiguration.FileName
                    : docSelected?.FileName
                }
              />
            </Box>
          </Dialog>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: "1em",
            mt: "2em",
          }}
        >
          <Typography
            sx={{ fontWeight: 550 }}
            variant="caption"
            color={"primary"}
          >
            Cantidad de solicitudes masivas: {fileState?.item?.Grupos?.length}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <AlertMU
            sx={{
              backgroundColor: "#FFB80059",
              color: "#000000",
            }}
            severity="info"
          >
            Podrás visualizar la función de cada uno de los participantes. Serán
            notificados cuando sea su turno de ejecutar su firma.
          </AlertMU>
        </Box>

        <Paper elevation={3} sx={{ overflow: "auto", maxHeight: 550 }}>
          <Box sx={{p: 1.5, mr: 2 }}>
            {fileState?.item?.Grupos?.map((groupUsers, index) => (
              <Box key={index + 1}>
                <AcordeonGroup Group={groupUsers} />
              </Box>
            ))}
          </Box>
        </Paper>

        <Grid container justifyContent="center">
          {/* {newFileState?.editStack ? (
            <Grid item xs={12} sm={4}>
              <Box textAlign={"center"}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  sx={{ maxWidth: 300, my: 1, minHeight: 45 }}
                  onClick={props.handleBackStep}
                >
                  Anterior
                </Button>
              </Box>
            </Grid>
          ) : null} */}
          <Grid item xs={12} sm={4}>
            <Box textAlign={"center"}>
              <Button
                variant="contained"
                fullWidth
                disabled={ fileState?.item === null}
                onClick={() => {
                  iniciarExpediente();
                }}
                sx={{ maxWidth: 300, my: 1, minHeight: 45 }}
              >
                Iniciar proceso
              </Button>
            </Box>
          </Grid>
        </Grid>
      </PreviewContainer>
    </RootStyle>
  );
};

const AcordeonGroup = (props) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          p: 0,
          "& .MuiAccordionSummary-content": {
            m: 0,
          },
        }}
      >
        <Typography variant="titulo">
          Participantes Clave de solicitud: {props.Group.Clave}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
        <ParticipantSortTableMasive
          listRows={props.Group.Participants}
          claveGrupo={props.Group.Clave}
          DataPrevious={props.Group.Prerequisites}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "100%",
  overflow: "auto",
  marginTop: 4,
  marginBottom: 3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const InfoLabel = (props) => {
  if (!props.label || !props.value) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography variant="subtitle1">{props.label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <BoxTexto>
          <Typography variant="subtitle1">{props.value}</Typography>
        </BoxTexto>
      </Grid>
    </Grid>
  );
};
