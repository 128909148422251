import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  styled,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useSelector } from "react-redux";

import { CustomButton } from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GetConfigStripe,
  RegisterProjectClientPlan,
  SeletedPlanUser,
} from "../../../store/actions";
import moment from "moment";
import { PaymentsService } from "../../../services/PaymentsService";
import { useState } from "react";
import BackdropComponent from "../../../components/BackDrop";
import { Alert } from "../../../components/Alert";

const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 18,
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
    marginTop: 10,
  },
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "50%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  textAlign: "right",
  width: "50%",
  mt: 0.3,
  mr: 5,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "left",
    fontWeight: 400,
  },
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 145,
  height: 50,
  padding: 6,
  "& .MuiSwitch-switchBase": {
    marginTop: 4,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(58px)",
      "& .MuiSwitch-thumb:before": {
        content: "'Anual'",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "absolute",
        fontSize: 15,
        left: 0,
        top: 0,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#C20E30",
    width: 85,
    height: 40,
    borderRadius: 22,
    "&::before": {
      content: "'Mensual'",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      position: "absolute",
      fontSize: 15,
      left: 0,
      top: 0,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 22,
  },
}));

export const BuySummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const stateRedux = useSelector((state) => state);
  const [planUserSeleted, setPlanUserSeleted] = useState(null);
  const statePlans = useSelector((state) => state.GetPlansPayment);
  const [fechaActual, setFechaActual] = useState(moment());

  const clientIdDs = useSelector((state) => state.DataPaymentClient?.items);
  const authUser = useSelector(
    (state) => state.Authentication?.items?.UserLoginData
  );

  useEffect(() => {
    setPlanUserSeleted(statePlans?.planUserSeleted);
  }, [statePlans?.planUserSeleted]);

  console.log("clientIdDs", stateRedux);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (clientIdDs?.referenceId !== null) {
        console.log("ENTROAQUI 1");
        dispatch(
          GetConfigStripe({
            referenceData: "",
          })
        );
        dispatch(
          RegisterProjectClientPlan({
            referenceData: "",
            clientId: clientIdDs?.client_Id,
            planId: planUserSeleted?.id,
          })
        );
        dispatch(SeletedPlanUser(planUserSeleted));
        setLoading(false);
        navigate("/inbox/PaymentUserData");
        console.log(data);
      } else {
        console.log("ENTROAQUI 2");
        const paymentsService = PaymentsService.getInstance();
        const responseRegisterCliente = await paymentsService.RegisterClient({
          referenceData: "",
          clientId: clientIdDs?.client_Id,
          name: clientIdDs?.name,
          phoneNumber: authUser?.PhoneNumber,
          email: clientIdDs?.email,
          isFreeTrial: false,
        });
        console.log("responseRegisterCliente", responseRegisterCliente);
        setResponseData(responseRegisterCliente);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (responseData !== null) {
      if (
        responseData.data.responseCodeMessage != null &&
        responseData.data.clientId == null
      ) {
        Alert(
          {
            icon: "error",
            text: responseData.data.responseCodeMessage,
            okbtntext: "Aceptar",
          },
          () => {
            navigate("/inbox/payments");
            setLoading(false);
          }
        );
      } else if (responseData.data.clientId != null) {
        console.log("responseData", responseData);
        dispatch(
          GetConfigStripe({
            referenceData: "",
          })
        );
        dispatch(
          RegisterProjectClientPlan({
            referenceData: "",
            clientId: clientIdDs?.client_Id,
            planId: planUserSeleted?.id,
          })
        );
        dispatch(SeletedPlanUser(planUserSeleted));
        setLoading(false);
        navigate("/inbox/PaymentUserData");
        console.log(responseData);
      }
    }
  }, [responseData?.data]);

  const onChangeSwitch = (event) => {
    const newValue = event.target.checked ? 6 : 2;
    console.log("newValue", newValue);

    const newState = statePlans?.plans?.plans.find(
      (item) => item.id === newValue
    );
    setPlanUserSeleted(newState);
  };

  console.log("planUserSeleted", planUserSeleted);

  useEffect(() => {
    if (planUserSeleted?.periodicity == "Mensual") {
      setFechaActual(moment().clone().add(1, "months"));
    } else if (planUserSeleted?.periodicity == "Anual") {
      setFechaActual(moment().clone().add(1, "years"));
    }
  }, [planUserSeleted]);

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <BackdropComponent loading={loading} />
      <Grid item xs={12} lg={11} md={12}>
        <Box sx={{ m: "1.5em" }}>
          <Typography variant="titulo" sx={{ fontWeight: 550 }}>
            Confirmación de compra
          </Typography>
        </Box>
        <Card
          elevation={3}
          sx={(theme) => ({ minWidth: 275, mt: "2em", m: "1em" })}
        >
          <CardContent>
            <Typography variant="titulo" sx={{ fontWeight: 550 }}>
              Gracias por contratar SoluSign
            </Typography>
            <br />
            <br />
            <Box>
              <BoxTitulo>
                <Typography variant="h6" sx={{ fontWeight: 550 }}>
                  Información de tu plan
                </Typography>
              </BoxTitulo>
              <BoxRexponsiveText
                Title={"Vigencia de tu plan: "}
                Subtitle={`${fechaActual.format("DD-MM-YYYY")}`}
              />
              <Box sx={{ mt: 1.3 }}>
                <Divider sx={{ color: "#C5C8CD" }} />
              </Box>
              <br />
              <br />
              <br />
              <BoxTitulo>
                <Typography variant="h6" sx={{ fontWeight: 550 }}>
                  Detalle de tu compra
                </Typography>
              </BoxTitulo>
              {statePlans?.planUserSeleted.id == 2 ? (
                <>
                  <Box sx={{ mt: 1.3 }}>
                    <Divider sx={{ color: "#C5C8CD" }} />
                  </Box>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle" sx={{ fontWeight: 450 }}>
                        Opción de pago
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box sx={{}}>
                          <FormControlLabel
                            control={
                              <MaterialUISwitch
                                onChange={onChangeSwitch}
                              />
                            }
                          />
                        </Box>
                      </Box>
                    </BoxTexto>
                  </BoxContainer>
                </>
              ) : null}
              <Box sx={{ mt: 1.3 }}>
                <Divider sx={{ color: "#C5C8CD" }} />
              </Box>
              <BoxRexponsiveText
                Title={"Plan seleccionado"}
                Subtitle={planUserSeleted?.name}
              />
              <Box sx={{ mt: 1.3 }}>
                <Divider sx={{ color: "#C5C8CD" }} />
              </Box>
              <BoxRexponsiveText
                Title={"Solicitudes:"}
                Subtitle={`${planUserSeleted?.maxTransactions} solicitudes`}
              />
              <Box sx={{ mt: 1.3 }}>
                <Divider sx={{ color: "#C5C8CD" }} />
              </Box>
              <BoxRexponsiveText
                Title={"Fecha de contratación:"}
                Subtitle={moment().format("DD-MM-YYYY")}
              />
              <Box sx={{ mt: 1.3 }}>
                <Divider sx={{ color: "#C5C8CD" }} />
              </Box>
              <BoxRexponsiveText
                Title={"Precio:"}
                Subtitle={`$${planUserSeleted?.price} / MXN`}
              />
              <Box sx={{ mt: 1.3 }}>
                <Divider sx={{ color: "#C5C8CD" }} />
              </Box>
              <BoxRexponsiveText
                Title={"IVA:"}
                Subtitle={`$${planUserSeleted?.iva} / MXN`}
              />
              <Box sx={{ mt: 1.3 }}>
                <Divider sx={{ color: "#C20E30", border: 2 }} />
              </Box>
              <BoxRexponsiveText
                Title={"Total:"}
                Subtitle={`$${planUserSeleted?.totalPay} / MXN`}
              />
            </Box>
            <Box
              fullWidth
              sx={{
                p: 1,
                display: "flex",
                justifyContent: "center",
                width: "100%",
                justifyItems: "center",
              }}
            >
              <Typography variant="subtitle">
                He leído y autorizo la{" "}
                <a
                  style={{ color: "#C20E30" }}
                  href={process.env.REACT_APP_URL_SOLUSIGN_POLITICAS}
                  target="_blank"
                  rel="noreferrer"
                >
                  Aviso de Privacidad, Protección de datos
                </a>{" "}
                y los{" "}
                <a
                  style={{ color: "#C20E30" }}
                  href={process.env.REACT_APP_URL_SOLUSIGN_TERMINOS_CONDICIONES}
                  target="_blank"
                  rel="noreferrer"
                >
                  Términos y condiciones.
                </a>
              </Typography>
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "center" }}>
            <CustomButton
              onClick={() => navigate("/inbox/payments")}
              variant="outline"
              sx={{
                marginTop: "1.5em",
                marginBottom: "1.5em",
                maxWidth: "454px",
                height: "50px",
              }}
            >
              Regresar
            </CustomButton>
            <CustomButton
              onClick={onSubmit}
              sx={{
                marginTop: "1.5em",
                marginBottom: "1.5em",
                maxWidth: "454px",
                height: 48,
              }}
            >
              Pagar ahora
            </CustomButton>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

const BoxRexponsiveText = (props) => {
  return (
    <BoxContainer>
      <BoxTitulo>
        <Typography variant="subtitle" sx={{ fontWeight: 400 }}>
          {props.Title}
        </Typography>
      </BoxTitulo>
      <BoxTexto>
        <Typography variant="subtitle" sx={{ fontWeight: 400 }}>
          {props.Subtitle}
        </Typography>
      </BoxTexto>
    </BoxContainer>
  );
};
