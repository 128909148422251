import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import CapchaComponent from "./CapchaComponent";
import { ToastNotification } from "../../../components/toastNotification";
import { FileService } from "../../../services/FileService";
import { LoadingButton } from "@mui/lab";
import BackdropComponent from "../../../components/BackDrop";
import { InputField } from "../../../components/InputField";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { styled } from "@mui/system";
import { Alert } from "../../../components/Alert";
import "./styles.css";
import { makeStyles } from "@mui/styles";
import crearCuenta from '../../../assets/crearcuenta.png'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { renderToString } from "react-dom/server";
import { UsersService } from "../../../services/UsersService";

const InputTextDefault = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 12,
    fontSize: 14,
    "& fieldset legend span": {
      display: "none",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: 14,
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& label.Mui-error": {
    color: "#FF4842 !important",
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
}));

const useStyles = makeStyles((theme) => ({
  //Estílos solamente del componente "LoginComponent"
  containerImagen: {
    height: "100vh",

  },
  imagenIlustrativa: {
    height: "100%",
    width: "100%",
    backgroundRepeat: 'none',
    backgroundSize: '100%',
    objectFit: 'cover',
  },
  formInput: {
    width: "100%",
    padding: 1,
  },
  boxReponsive: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  containerForm: {
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  }
}));

export default function NewUserComponent(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const stageData = useSelector((state) => state.FileFlow);
  const [lada, setLada] = useState();
  const [stateCaptcha, setStateCaptcha] = useState(false);
  const [blocked, setBlocked] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState()
  const query = new URLSearchParams(useLocation().search);
  const [showPass, setshowPass] = useState(true);
  const [showPassDos, setshowPassDos] = useState(true);
  const [newData, setNewData] = useState([]);
  const [loading, setloading] = useState(false);
  const [idRef, setIdRef] = useState('')
  const { control, handleSubmit, reset } = useForm();
  const questionId = query.get("ActionId");
  const Email = query.get("Email");

  const dataUsuario = useCallback(async () => {
    try {
      const fileService = FileService.getInstance();
      const response = await fileService.getUserActionCustom({
        ActionUid: questionId,
      });
      console.log('response', response.Body)
      if (response.Body && response.Body.Locked === false) {
        Alert({
          icon: "success",
          text: `El correo ${response.Body.Email} ya se encuentra registrado en el sistema.`,
          okbtntext: "Aceptar",
        });
        return navigate("/auth/login")
      } else {
        setNewData(response.Body);
        return response;
      }
    } catch (error) {
      console.log('error', error)
      Alert({
        icon: "error",
        text: error.message,
        okbtntext: "Aceptar",
      });
      return navigate("/auth/login")
    }
  }, [questionId]);

  const dataEmail = useCallback(async () => {
    const usersService = UsersService.getInstance();
    const response = await usersService.getUserByCorrero({
      SearchText: Email,
    });
    console.log('BODY CORREO', response)
    setNewData(response.Body);
    Alert({
      icon: "warnign",
      text: "Para comenzar a realizar acciones en tu solicitud, es necesario que completes el registro",
      okbtntext: "Aceptar",
    });
    return response;
  }, [Email]);

  useEffect(() => {
    if (questionId) {
      dataUsuario();
    } else if (Email) {
      dataEmail();
    }
  }, [questionId, dataUsuario, Email]);

  const handleCreate = async (data) => {
    setloading(true);
    const ochocaracteres = /.{8,}/.test(data.Password);
    const mayymin =
      /(?:[A-Z])/.test(data.Password) && /(?:[a-z])/.test(data.Password);
    const numeros = /(?:\d)/.test(data.Password);
    const noespecial = /[^!?A-Za-z\d]/.test(data.Password);
    const espacios = /\s/.test(data.Password);

    const valida = ochocaracteres && mayymin && numeros && noespecial;

    if (data.Avisos === false || data.Avisos === undefined) {
      ToastNotification({
        icon: "warning",
        text: "Acepta los terminos y condiciones para poder continuar",
      });
      setloading(false);
      return null;
    }

    if (valida === false || espacios) {
      Alert({
        icon: "error",
        title: "Revisa tu contraseña",
        text: `Revisa que tu contraseña cumpla lo siguiente: ${espacios ? 'No contenga espacios, ' : ''}
        ${ochocaracteres === false ? '8 caracteres mínimo, ' : ''}
        ${mayymin === false ? 'al menos una letra mayúscula y letras minúsculas, ' : ''}
        ${numeros === false ? 'al menos un número, ' : ''}
        ${noespecial === false ? 'mínimo un caracter especial.' : ''} `,
      });
      setloading(false);
      return null;
    }

    if (!phoneNumber) {
      ToastNotification({
        icon: "warning",
        text: "Completa tu número telefónico",
      });
      setloading(false);
      return null;
    }

    if (data.Password !== data.RepeatPassword) {
      ToastNotification({
        icon: "error",
        text: "Las contraseñas no coinciden",
      });
      setloading(false);
      return null;
    }


    if (questionId || Email) {
      try {
        const fileService = FileService.getInstance();
        const response = await fileService.updateUserCustom({
          Id: newData.Id,
          Password: data.Password,
          Name: data.Name,
          FatherLastName: data.FatherLastName,
          MotherLastName: '',
          Email: newData.Email,
          PhoneNumber: phoneNumber,
          Metadata: [{
            Name: 'CURP',
            GroupName: 'Datos personales',
            Value: ''
          }],
          IsUpdateUser: false,
        });
        console.log(response)
        if (response.Body) {
          Alert(
            {
              title: "Registro exitoso",
              icon: "success",
              okbtntext: "Salir",
              html: renderToString(
                <Box>
                  <Typography
                    textAlign={"justify"}
                    sx={{ maxWidth: 400, mx: "auto", fontFamily: '"Montserrat",sans-serif' }}
                  >
                    A partir de ahora podrás continuar el proceso para la firma
                    del expediente. Recuerda que cuando sea tu turno de
                    participar te enviaremos una notificación a tu correo
                    electrónico.
                  </Typography>
                  <Divider sx={{ maxWidth: 300, mt: 3, mx: "auto" }} />
                </Box>
              ),
            },
            navigate("/auth/login")
          );
        }
        setloading(false);
      } catch (error) {
        console.log(error);
        ToastNotification({
          icon: "error",
          text: "Error al completar el usuario",
        });
      } finally {
        setloading(false);
        reset();
      }
    } else {
      try {
        const fileService = FileService.getInstance();
        const res = await fileService.registerParticipantOperador({
          UserName: "",
          Password: data.Password,
          Name: newData.Name,
          FatherLastName: data.FatherLastName,
          MotherLastName: '',
          Email: newData.Email,
          PhoneNumber: phoneNumber,
          Roles: [{ Id: 14, Name: "Operador" }],
        });
        console.log(res);
        ToastNotification({ text: "Usuario creado con éxito" });
        navigate("/auth/confirmRegister");
      } catch (error) {
        console.log(error);
        ToastNotification({
          icon: "error",
          text: `Error al crear el usuario. ${error.message}`,
        });
      } finally {
        setloading(false);
        reset();
      }
    }
  };


  return (
    <Fragment>
      <BackdropComponent loading={stageData.loading} />
      <Grid container>
        <Grid sx={{ height: '100vh', overflow: "auto" }} container justifyContent={"center"} alignItems={'center'} item lg={6} md={6} sm={12} xs={11} >
          <Box className={classes.containerForm}>
            <Box sx={{ mt: 2 }}>
              <Typography variant="titulo">
                {questionId
                  ? "Actualización de datos del participante"
                  : "Bienvenido"}
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant={"body2"}>
                -Lee nuestro{" "}
                <a
                  href={process.env.REACT_APP_URL_SOLUSIGN_POLITICAS}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#C20E30" }}
                  rel="noreferrer"
                >
                  Aviso de Privacidad, Protección de datos
                </a> y
                <a
                  href={process.env.REACT_APP_URL_SOLUSIGN_TERMINOS_CONDICIONES}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#C20E30" }}
                  rel="noreferrer"
                >
                  Términos y condiciones
                </a>
              </Typography>
              <Typography variant={"body2"}>
                -Llena el siguiente formulario con la información solicitada
              </Typography>
            </Box>
            <Box sx={{ width: "100%", mt: 2 }}>
              <InputTextDefault
                size="small"
                fullWidth
                disabled={questionId || Email ? true : false}
                label="Correo electrónico"
                value={newData?.Email}
                onChange={(e) =>
                  setNewData({ ...newData, Email: e.target.value })
                }
              />
              <InputTextDefault
                size="small"
                fullWidth
                label="Alias"
                value={newData?.UserAlias}
                disabled={questionId || Email ? true : false}
                onChange={(e) =>
                  setNewData({ ...newData, UserAlias: e.target.value })
                }
              />
              <InputField
                name="Name"
                label="Nombre (s)"
                iconSpam={true}
                control={control}
                fullWidth
                sx={{ borderRadius: "12px" }}
                validations={{ required: true }}
              />
              <InputField
                name="FatherLastName"
                label="Apellido (s)"
                control={control}
                iconSpam={true}
                fullWidth
                sx={{ borderRadius: "12px" }}
                validations={{ required: true }}
              />
              <Box sx={{ mt: 2 }}>
                <Typography>
                  <b>Número de celular {lada?.name === 'Mexico' ? '(a 10 dígitos)' : null} <spam style={{ color: "#C20E30", marginLeft: 3 }}>*</spam></b>
                </Typography>
                <PhoneInput
                  // onlyCountries={['mx', 'at']}
                  country={"mx"}
                  inputClass={'classesContainerPaises'}
                  enableSearch={true}
                  onChange={(value, country, e, formattedValue) => { setPhoneNumber(formattedValue); setLada(country) }}
                />
              </Box>
              {/* {newData?.Metadata?.map(getTypeField)} */}
              <InputField
                name="Password"
                iconSpam={true}
                label="Contraseña"
                control={control}
                fullWidth
                sx={{ borderRadius: "12px" }}
                validations={{ required: true }}
                posIcon="end"
                type="password"
                isPassword={showPass}
                icon={
                  showPass ? (
                    <IconButton onClick={() => setshowPass(false)}>
                      <VisibilityOff />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setshowPass(true)}>
                      <Visibility />
                    </IconButton>
                  )
                }
              />
              <FormHelperText >
                La contraseña debe de contener al menos 8 caracteres, sin espacios, una mayúscula, una
                minúscula, un número y un carácter especial
              </FormHelperText>
              <InputField
                name="RepeatPassword"
                iconSpam={true}
                label="Repetir contraseña"
                control={control}
                fullWidth
                sx={{ borderRadius: "12px" }}
                validations={{ required: true }}
                posIcon="end"
                type="password"
                isPassword={showPassDos}
                icon={
                  showPassDos ? (
                    <IconButton onClick={() => setshowPassDos(false)}>
                      <VisibilityOff />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setshowPassDos(true)}>
                      <Visibility />
                    </IconButton>
                  )
                }
              />
              {/* <FormHelperText error={validationPass === false || validSpace ? true : false}>
                Confirmar contraseña
              </FormHelperText> */}
              <Box sx={{ mt: 1 }}>
                <InputField
                  name="Avisos"
                  label={`
                  <Typography variant="body2">
                    <spam className='enlace'>* </spam>He leído y acepto el
                    <a
                      href='${process.env.REACT_APP_URL_SOLUSIGN_POLITICAS}'
                      target="_blank"
                      className='enlace'
                      rel="noreferrer"
                    >
                      Aviso de Privacidad, Protección de datos
                    </a> y 
                    <a
                    href='${process.env.REACT_APP_URL_SOLUSIGN_TERMINOS_CONDICIONES}'
                    target="_blank"
                    className='enlace'
                    rel="noreferrer"
                  >
                  Términos y condiciones
                  </a>
                    de DocSolutions
                  </Typography>`}
                  control={control}
                  inputType="check"
                  fullWidth
                  sx={{ borderRadius: "12px" }}
                // validations={{ required: true }}
                />
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "left",
                  justifyItems: "center",
                }}
              >
                <CapchaComponent setIdRef={setIdRef} setStateCaptcha={setStateCaptcha} setBlocked={setBlocked} />
              </Box>
              <Box sx={{ p: 1, mt: 1, textAlign: "center" }}>
                <LoadingButton
                  loading={stateCaptcha || loading}
                  disabled={blocked}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    fontSize: "16px !important",
                    height: 38,
                    fontWeight: 400,
                    letterSpacing: 1.5,
                  }}
                  onClick={handleSubmit(handleCreate)}
                >
                  {questionId ? "Completar registro" : "Regístrate"}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
        >
          <Box className={classes.containerImagen}>
            <img
              className={classes.imagenIlustrativa}
              src={crearCuenta}
              alt="Imagen ilustrativa"
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
